import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () => import('@/containers/TheContainer');

//Componentes
const inicio = () => import('@/views/base/Inicio');
const Login = () => import('@/views/pages/Login');

const userTable = () => import('@/views/base/userTable');
const alertTable = () => import('@/views/base/alertTable');
const alertsTypeTable = () => import('@/views/base/alertsTypeTable');
const sessionTable = () => import('@/views/base/sessionTable');
const adviceTable = () => import('@/views/base/adviceTable');
const pathologyTable = () => import('@/views/base/pathologyTable');
const challengeTable = () => import('@/views/base/challengeTable');
const challengesTypeTable = () => import('@/views/base/challengesTypeTable');

const editAlert = () => import('@/views/base/editAlert');
const editAdvice = () => import('@/views/base/editAdvice');
const editPathology = () => import('@/views/base/editPathology');
const editChallenge = () => import('@/views/base/editChallenge');

const assignPathologies = () => import('@/views/base/assignPathologies');
const assignChallenges = () => import('@/views/base/assignChallenges');
const assignAlerts = () => import('@/views/base/assignAlerts');
const assignLimits = () => import('@/views/base/assignLimits');
const sessionDetails = () => import('@/views/base/sessionDetails');

const addUser = () => import('@/views/base/addUser');


const notFound = () => import('@/views/pages/Page404');
const errorServer = () => import('@/views/pages/Page500');

Vue.use(Router)

export default new Router({
  mode: 'hash',
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

function configRoutes() {
  return [
    {
      path: '/',
      redirect: '/home',
      name: 'Home',
      component: TheContainer,
      children: [
        {
          path: 'home',
          name: 'Inicio',
          alias: '/',
          component: inicio,
          meta: {
            title: 'Inicio',
          }
        },
        {
          path: 'user',
          name: 'Usuarios',
          component: userTable,
          meta: {
            title: 'Usuarios',
          }
        },
        {
          path: 'user',
          redirect: 'user',
          name: 'Usuarios',
          component: addUser,
          children: [          
            {
              path: 'info/:id',
              name: 'Información del usuario',
              component: addUser,
              meta: {
                title: 'Información del usuario',
              }
            }
          ]
        },
        {
          path: 'user',
          redirect: 'user',
          name: 'Usuarios',
          component: addUser,
          children: [          
            {
              path: 'create',
              name: 'Crear usuario',
              component: addUser,
              meta: {
                title: 'Crear usuario',
              }
            },
          ]
        },
        {
          path: 'user',
          redirect: 'user',
          name: 'Usuarios',
          component: assignPathologies,
          children: [          
            {
              path: 'pathology/:id',
              name: 'Asignar patologias',
              component: assignPathologies,
              meta: {
                title: 'Asignar patologías',
              }
            },
          ]
        },
        {
          path: 'user',
          redirect: 'user',
          name: 'Usuarios',
          component: assignChallenges,
          children: [          
            {
              path: 'challenge/:id',
              name: 'Asignar retos',
              component: assignChallenges,
              meta: {
                title: 'Asignar retos',
              }
            },
          ]
        },
        {
          path: 'user',
          redirect: 'user',
          name: 'Usuarios',
          component: assignAlerts,
          children: [          
            {
              path: 'alert/:id',
              name: 'Asignar alertas',
              component: assignAlerts,
              meta: {
                title: 'Asignar alertas',
              }
            },
          ]
        },
        {
          path: 'user',
          redirect: 'user',
          name: 'Usuarios',
          component: assignLimits,
          children: [          
            {
              path: 'limit/:id',
              name: 'Asignar límites',
              component: assignLimits,
              meta: {
                title: 'Asignar límites',
              }
            },
          ]
        },
        {
          path: 'user',
          redirect: 'user',
          name: 'Usuarios',
          component: sessionTable,
          children: [          
            {
              path: 'sessions/:id',
              name: 'Ver sesiones',
              component: sessionTable,
              meta: {
                title: 'Ver sesiones',
              }
            },
          ]
        },{
          path: 'user',
          redirect: 'user',
          name: 'Usuarios',
          component: sessionDetails,
          children: [          
            {
              path: 'sessions/:id',
              name: 'Ver sesiones',
              component: sessionDetails,
              children: [
                {
                  path: 'details/:id_session',
                  name: 'Ver detalles sesión',
                  component: sessionDetails,
                  meta: {
                    title: 'Ver detalles sesión',
                  }
                },

              ]
            },
          ]
        },
        {
          path: 'alert',
          name: 'Alertas',
          component: alertTable,
          meta: {
            title: 'Alertas',
          }
        },
        {
          path: 'alert',
          redirect: '/alert',
          name: 'Alertas',
          component: editAlert,
          children: [
            {
              path: 'create',
              name: 'Crear Alerta',
              component: editAlert,
              meta: {
                title: 'Crear Alerta',
              }
            }
          ]
        },
        {
          path: 'alert',
          name: 'Alertas',
          component: alertsTypeTable,
          children: [
            {
              path:'types',
              name: 'Tipos de alerta',
              component: alertsTypeTable,
              meta: {
                title: 'Tipos de alerta',
              }
            }
          ]
        },
        {
          path: 'challenge',
          name: 'Retos',
          component: challengeTable,
          meta: {
            title: 'Retos',
          }
        },
        {
          path: 'challenge',
          name: 'Retos',
          component: editChallenge,
          children: [
            {
              path:'create',
              name: 'Crear Reto',
              component: editChallenge,
              meta: {
                title: 'Crear Reto',
              }
            }
          ]
        },
        {
          path: 'challenge',
          name: 'Retos',
          component: challengesTypeTable,
          children: [
            {
              path:'types',
              name: 'Tipos de reto',
              component: challengesTypeTable,
              meta: {
                title: 'Tipos de reto',
              }
            }
          ]
        },
        {
          path: 'advice',
          name: 'Consejos',
          component: adviceTable,
          meta: {
            title: 'Consejos',
          }
        },
        {
          path: 'advice',
          redirect: '/consejo',
          name: 'Consejos',
          component: editAdvice,
          children: [
            {
              path: 'create',
              name: 'Crear Consejo',
              component: editAdvice,
              meta: {
                title: 'Crear Consejo',
              }
            },
            {
              path: ':nombre/:id',
              name: 'Editar Consejo',
              component: editAdvice,
              meta: {
                title: 'Editar Consejo',
              }
            }
          ]
        },
        {
          path: 'pathology',
          name: 'Patologias',
          component: pathologyTable,
          meta: {
            title: 'Patologías',
          }
        },
        {
          path: 'pathology',
          redirect: '/pathology',
          name: 'Patologias',
          component: editPathology,
          children: [
            {
              path: 'create',
              name: 'Crear Patología',
              component: editPathology,
              meta: {
                title: 'Crear Patología',
              }
            },
            {
              path: 'edit/:id',
              name: 'Editar Patología',
              component: editPathology,
              meta: {
                title: 'Editar Patología',
              }
            }
          ]
        },
        {
          path: 'sessions',
          name: 'Sesiones',
          component: sessionTable
        },
        {
          path: '500',
          name: 'Server Error',
          component: errorServer,
          meta: {
            title: '500',
          }
        },
      ]
    },
    {
      path: '/pages',
      redirect: '/pages/example',
      name: 'Pages',
      component: {
        render(c) { return c('router-view') }
      },
      children: [
        {
          path: 'login',
          name: 'Login',
          component: Login,
          meta: {
            title: 'Login',
          }
        },

      ]
    },
    {
      path: '*',
      name: 'not found',
      alias: '/404',
      component: notFound,
      meta: {
        title: '404',
      }
    },

  ]
}